<template>
  <div class="table__row">
    <div class="container">
      <div class="table__row-inner">
        <div class="table__cell">
          <div class="table__cell-title">{{ dataExport.id }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ moment(dataExport.date).format('MMM YYYY') }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ dataExport.invoiceNo }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ dataExport.fuelSurCharge }}</div>
        </div>
        <div class="table__cell status-cell">
          <div v-if="dataExport.status === 'canceled'" class="indicator indicator--red">Canceled</div>
          <div v-else-if="dataExport.status === 'inProgress'" class="indicator indicator--orange">In Progress</div>
          <div v-else-if="dataExport.status === 'completed'" class="indicator indicator--green">Completed</div>
          <div v-else-if="dataExport.status === 'error'" class="indicator indicator--red">Error</div>
        </div>
        <div class="table__cell table__cell--end">
          <el-dropdown trigger="click" @command="action">
            <i class="ri-more-fill"></i>
            <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
              <el-dropdown-item
                class="item--edit"
                :class="{'item--unactive': dataExport.status !== 'completed'}"
                command="download"
              >
                <i class="ri-file-download-fill"></i> Download
              </el-dropdown-item>
              <el-dropdown-item
                class="item--edit"
                :class="{'item--unactive': dataExport.status !== 'inProgress'}"
                command="restart"
              >
                <i class="ri-file-upload-fill"></i> Restart
              </el-dropdown-item>
              <el-dropdown-item
                class="item--remove"
                :class="{'item--unactive': dataExport.status !== 'canceled'}"
                command="cancel"
              >
                <i class="ri-file-forbid-fill"></i> Cancel
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="table__row-card card">
        <div class="card__header">
          <div class="card__box">
            <div class="card__title">ID {{ dataExport.id }}</div>
          </div>
          <div class="card__box">
            <div class="card__title">{{ moment(dataExport.date).format('MMM YYYY') }}</div>
          </div>
          <div class="card__box">
            <div v-if="dataExport.status === 'canceled'" class="indicator indicator--red">Canceled</div>
            <div v-else-if="dataExport.status === 'inProgress'" class="indicator indicator--orange">In Progress</div>
            <div v-else-if="dataExport.status === 'completed'" class="indicator indicator--green">Completed</div>
            <div v-else-if="dataExport.status === 'error'" class="indicator indicator--red">Error</div>
          </div>
          <div class="card__box" @click.stop>
            <el-dropdown trigger="click" @command="action">
              <i class="ri-more-fill"></i>
              <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                <el-dropdown-item
                  class="item--edit"
                  :class="{'item--unactive': dataExport.status !== 'completed'}"
                  command="download"
                >
                  <i class="ri-file-download-fill"></i> Download
                </el-dropdown-item>
                <el-dropdown-item
                  class="item--edit"
                  :class="{'item--unactive': dataExport.status !== 'inProgress'}"
                  command="restart"
                >
                  <i class="ri-file-upload-fill"></i> Restart
                </el-dropdown-item>
                <el-dropdown-item
                  class="item--remove"
                  :class="{'item--unactive': dataExport.status !== 'canceled'}"
                  command="cancel"
                >
                  <i class="ri-file-forbid-fill"></i> Cancel
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="card__body">
          <div class="card__line">
            <div class="card__subtitle">Fuel Surcharge</div>
            <div class="card__title">{{ dataExport.fuelSurCharge }}</div>
          </div>
          <div class="card__line">
            <div class="card__subtitle">Invoice No</div>
            <div class="card__title">{{ dataExport.invoiceNo }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapActions, mapState} from 'vuex'
export default {
  name: 'ExportedDeliveries',
  props: {
    dataExport: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      moment
    }
  },
  computed: {
    ...mapState({
      csv: s => s.deliveries.exports.csv
    })
  },
  methods: {
    ...mapActions({
      download: 'deliveries/exports/download',
      cancel: 'deliveries/exports/cancel',
      restart: 'deliveries/exports/restart'
    }),
    action(act) {
      if (act === 'download') {
        if (this.dataExport.status === 'completed') this.downloadCSV()
      } else if (act === 'restart') {
        if (this.dataExport.status === 'canceled') this.cancelExport()
      } else if (act === 'cancel') {
        if (this.dataExport.status === 'inProgress') this.restartExport()
      }
    },
    async restartExport() {
      const data = await this.restartExport(this.dataExport.id)
      if (data.success) {
        alert('Export restarted successfully')
        this.dataExport.status = 'inProgress'
      } else {
        alert('This export can not be restarted')
      }
    },
    async cancelExport() {
      const data = await this.restart(this.dataExport.id)
      if (data.success) {
        alert('Export canceled successfully')
        this.dataExport.status = 'canceled'
      } else {
        alert('This export can not be canceled')
      }
    },
    async downloadCSV() {
      await this.download(this.dataExport.id)
      const fileURL = window.URL.createObjectURL(new Blob([this.csv]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', this.dataExport.details)
      document.body.appendChild(fileLink)
      fileLink.click()
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
