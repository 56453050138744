<template>
  <div class="shifts__wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container">
          <div class="page-title-inner">
            <div class="shifts__title page-title">Export Deliveries tool</div>
            <button class="button button--fill" @click="showSidebar">
              <i class="ri-add-circle-fill" />
              <span>Export Deliveries</span>
            </button>
            <button
              class="icon-button icon-button--round icon-button--mobile icon-button--blue button-fly"
              @click="showSidebar"
            >
              <i class="ri-add-circle-fill"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="table">
        <div class="table__title-wrapper">
          <div class="container">
            <div class="table__title-inner">
              <div class="table__title-item">ID</div>
              <div class="table__title-item">Date</div>
              <div class="table__title-item">Invoice No.</div>
              <div class="table__title-item">Fuel Surcharge</div>
              <div class="table__title-item">Status</div>
            </div>
          </div>
        </div>
        <div class="table__main">
          <ExportedDeliveries v-for="expDeliveries in exports" :key="expDeliveries.id" :data-export="expDeliveries" />
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import ExportedDeliveries from './ExportedDeliveries'
import InfiniteLoading from 'vue-infinite-loading'
import {mapState, mapActions} from 'vuex'

export default {
  name: 'ExportDeliveries',
  components: {ExportedDeliveries, InfiniteLoading},
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapState({
      exports: s => s.deliveries.exports.exports,
      pagination: s => s.deliveries.exports.pagination,
      page: s => s.deliveries.exports.page
    }),
    hasMoreResults() {
      return this.exports.length < this.pagination.total && this.page > 1
    }
  },
  async created() {
    this.fetchExports()
  },
  methods: {
    ...mapActions({
      fetchExports: 'deliveries/exports/fetch'
    }),
    showSidebar() {
      this.$root.$emit('ShowSidebar', 'AddDataExport')
    },
    async infLoad() {
      this.isLoading = true
      try {
        await this.fetchExports()
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
