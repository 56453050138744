var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table__row" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "table__row-inner" }, [
        _c("div", { staticClass: "table__cell" }, [
          _c("div", { staticClass: "table__cell-title" }, [
            _vm._v(_vm._s(_vm.dataExport.id))
          ])
        ]),
        _c("div", { staticClass: "table__cell" }, [
          _c("div", { staticClass: "table__cell-title" }, [
            _vm._v(_vm._s(_vm.moment(_vm.dataExport.date).format("MMM YYYY")))
          ])
        ]),
        _c("div", { staticClass: "table__cell" }, [
          _c("div", { staticClass: "table__cell-title" }, [
            _vm._v(_vm._s(_vm.dataExport.invoiceNo))
          ])
        ]),
        _c("div", { staticClass: "table__cell" }, [
          _c("div", { staticClass: "table__cell-title" }, [
            _vm._v(_vm._s(_vm.dataExport.fuelSurCharge))
          ])
        ]),
        _c("div", { staticClass: "table__cell status-cell" }, [
          _vm.dataExport.status === "canceled"
            ? _c("div", { staticClass: "indicator indicator--red" }, [
                _vm._v("Canceled")
              ])
            : _vm.dataExport.status === "inProgress"
            ? _c("div", { staticClass: "indicator indicator--orange" }, [
                _vm._v("In Progress")
              ])
            : _vm.dataExport.status === "completed"
            ? _c("div", { staticClass: "indicator indicator--green" }, [
                _vm._v("Completed")
              ])
            : _vm.dataExport.status === "error"
            ? _c("div", { staticClass: "indicator indicator--red" }, [
                _vm._v("Error")
              ])
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "table__cell table__cell--end" },
          [
            _c(
              "el-dropdown",
              { attrs: { trigger: "click" }, on: { command: _vm.action } },
              [
                _c("i", { staticClass: "ri-more-fill" }),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "el-popper-custom el-popper--actions",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown"
                  },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        staticClass: "item--edit",
                        class: {
                          "item--unactive":
                            _vm.dataExport.status !== "completed"
                        },
                        attrs: { command: "download" }
                      },
                      [
                        _c("i", { staticClass: "ri-file-download-fill" }),
                        _vm._v(" Download ")
                      ]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        staticClass: "item--edit",
                        class: {
                          "item--unactive":
                            _vm.dataExport.status !== "inProgress"
                        },
                        attrs: { command: "restart" }
                      },
                      [
                        _c("i", { staticClass: "ri-file-upload-fill" }),
                        _vm._v(" Restart ")
                      ]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        staticClass: "item--remove",
                        class: {
                          "item--unactive": _vm.dataExport.status !== "canceled"
                        },
                        attrs: { command: "cancel" }
                      },
                      [
                        _c("i", { staticClass: "ri-file-forbid-fill" }),
                        _vm._v(" Cancel ")
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "table__row-card card" }, [
        _c("div", { staticClass: "card__header" }, [
          _c("div", { staticClass: "card__box" }, [
            _c("div", { staticClass: "card__title" }, [
              _vm._v("ID " + _vm._s(_vm.dataExport.id))
            ])
          ]),
          _c("div", { staticClass: "card__box" }, [
            _c("div", { staticClass: "card__title" }, [
              _vm._v(_vm._s(_vm.moment(_vm.dataExport.date).format("MMM YYYY")))
            ])
          ]),
          _c("div", { staticClass: "card__box" }, [
            _vm.dataExport.status === "canceled"
              ? _c("div", { staticClass: "indicator indicator--red" }, [
                  _vm._v("Canceled")
                ])
              : _vm.dataExport.status === "inProgress"
              ? _c("div", { staticClass: "indicator indicator--orange" }, [
                  _vm._v("In Progress")
                ])
              : _vm.dataExport.status === "completed"
              ? _c("div", { staticClass: "indicator indicator--green" }, [
                  _vm._v("Completed")
                ])
              : _vm.dataExport.status === "error"
              ? _c("div", { staticClass: "indicator indicator--red" }, [
                  _vm._v("Error")
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "card__box",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" }, on: { command: _vm.action } },
                [
                  _c("i", { staticClass: "ri-more-fill" }),
                  _c(
                    "el-dropdown-menu",
                    {
                      staticClass: "el-popper-custom el-popper--actions",
                      attrs: { slot: "dropdown" },
                      slot: "dropdown"
                    },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          class: {
                            "item--unactive":
                              _vm.dataExport.status !== "completed"
                          },
                          attrs: { command: "download" }
                        },
                        [
                          _c("i", { staticClass: "ri-file-download-fill" }),
                          _vm._v(" Download ")
                        ]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--edit",
                          class: {
                            "item--unactive":
                              _vm.dataExport.status !== "inProgress"
                          },
                          attrs: { command: "restart" }
                        },
                        [
                          _c("i", { staticClass: "ri-file-upload-fill" }),
                          _vm._v(" Restart ")
                        ]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          staticClass: "item--remove",
                          class: {
                            "item--unactive":
                              _vm.dataExport.status !== "canceled"
                          },
                          attrs: { command: "cancel" }
                        },
                        [
                          _c("i", { staticClass: "ri-file-forbid-fill" }),
                          _vm._v(" Cancel ")
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "card__body" }, [
          _c("div", { staticClass: "card__line" }, [
            _c("div", { staticClass: "card__subtitle" }, [
              _vm._v("Fuel Surcharge")
            ]),
            _c("div", { staticClass: "card__title" }, [
              _vm._v(_vm._s(_vm.dataExport.fuelSurCharge))
            ])
          ]),
          _c("div", { staticClass: "card__line" }, [
            _c("div", { staticClass: "card__subtitle" }, [
              _vm._v("Invoice No")
            ]),
            _c("div", { staticClass: "card__title" }, [
              _vm._v(_vm._s(_vm.dataExport.invoiceNo))
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }